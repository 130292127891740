import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";

function Navbar() {
  const { t, i18n } = useTranslation();
  const [isListOpen, setIsListOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const [currentElement, setCurrentElement] = useState(window.location.hash.slice(1));

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    // const sections =  sections are [home, services, faq, contact] divs with id attribute
    const sections = document.querySelectorAll(
      "div[id^='home'], div[id^='services'], div[id^='faq'], div[id^='contact']"
    );

    sections.forEach((section) => {
      // if the current scroll position is more than 70% of the section's top position
      // then set the currentElement to the section's id
      if (
        scrollPosition > section.offsetTop - section.offsetHeight * 0.7 &&
        scrollPosition < section.offsetTop + section.offsetHeight * 0.3
      ) {
        if (currentElement !== section.id) {
          setCurrentElement(section.id);
        }
      }
    });
  }, [currentElement]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setMaxHeight(isListOpen ? `${listRef.current.scrollHeight}px` : "0px");
  }, [isListOpen]);

  const listRef = useRef(null);
  return (
    <div className="backdrop-blur-lg border-b border-b-zinc-600/25 text-white flex justify-between items-center py-6 px-8 fixed z-[100] w-[100vw]">
      <div>
        <img src="/logo.png" alt="logo" className="h-8" draggable="false" />
      </div>
      <div className="hidden md:flex items-center">
        <div className="flex gap-4 text-lg lg:text-xl md:text-lg sm:text-base text-base">
          <a
            href="#home"
            className={`transition-all border-red mt-1 ${
              currentElement == `home` ? `border-b-2` : ``
            }`}
          >
            {t("home")}
          </a>
          <a
            href="#services"
            className={`transition-all border-red mt-1 ${
              currentElement == `services` ? `border-b-2` : ``
            }`}
          >
            {t("services")}
          </a>
          <a
            href="#faq"
            className={`transition-all border-red mt-1 ${
              currentElement == `faq` ? `border-b-2` : ``
            }`}
          >
            {i18n.language === "en" ? "FAQ" : t("faq")}
          </a>
          <a
            href="#contact"
            className={`transition-all border-red mt-1 ${
              currentElement == `contact` ? `border-b-2` : ``
            }`}
          >
            {t("contact")}
          </a>
        </div>
        <div>
          <button
            onClick={() =>
              i18n.changeLanguage(i18n.language === "en" ? "ar" : "en")
            }
            className="text-lg lg:text-xl md:text-lg sm:text-base mx-4 flex items-center justify-center gap-2 bg-zinc-700/25 border border-zinc-800/50 px-2 py-1 rounded-lg"
          >
            {i18n.language === "en" ? (
              <img
                src="https://flagicons.lipis.dev/flags/1x1/sa.svg"
                alt="sa"
                width="32"
                height="32"
                className="h-8 rounded-full"
              />
            ) : (
              <img
                src="https://flagicons.lipis.dev/flags/1x1/gb.svg"
                alt="us"
                width="32"
                height="32"
                className="h-8 rounded-full"
              />
            )}
            {i18n.language === "en" ? "عربي" : "English"}
          </button>
        </div>
      </div>
      <div className="md:hidden">
        <button
          className="text-white rounded-full"
          onClick={() => setIsListOpen(!isListOpen)}
        >
          <i className="bi bi-list text-4xl"></i>
        </button>
      </div>

      <div
        ref={listRef}
        className={`md:hidden absolute top-16 end-8 bg-black-2 rounded-lg transition-max-height ease-in-out overflow-hidden`}
        style={{
          maxHeight,
        }}
      >
        <div className="flex flex-col gap-4 text-lg p-4">
          <a href="#home">{t("home")}</a>
          <a href="#services">{t("services")}</a>
          <a href="#faq">{i18n.language === "en" ? "FAQ" : t("faq")}</a>
          <a href="#contact">{t("contact")}</a>
        </div>
        <div>
          <button
            onClick={() => {
              i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
              setIsListOpen(false);
            }}
            className="text-lg mx-4 flex gap-2 bg-black-2 px-2 py-1 rounded-lg"
          >
            {i18n.language === "en" ? (
              <img
                src="https://flagicons.lipis.dev/flags/1x1/sa.svg"
                alt="sa"
                width="32"
                height="32"
                className="h-8 rounded-full"
              />
            ) : (
              <img
                src="https://flagicons.lipis.dev/flags/1x1/gb.svg"
                alt="us"
                width="32"
                height="32"
                className="h-8 rounded-full"
              />
            )}
            {i18n.language === "en" ? "عربي" : "English"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
