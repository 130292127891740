import React, { useState, useEffect } from "react";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <button
      className={`bg-image w-20 h-20 fixed z-50 bottom-8 max-md:-bottom-12 max-md:-start-9 m-4 transition-all active:scale-125 hover:scale-105 ${
        isVisible ? `block show` : `opacity-0`
      }`}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <div className="max-md:mb-3">
      <i className="bi bi-chevron-up text-3xl text-white mb-1"></i>
      </div>
    </button>
  );
}

export default ScrollToTop;
