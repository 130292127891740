import React, { useState, useRef, useEffect } from "react";

function Details({ title, description }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className="py-6 px-2 border-b border-[#333333]">
      <div
        className={`text-2xl max-md:text-xl font-semibold text-white flex justify-between items-center cursor-pointer transition-all ${isOpen ? "text-green" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <i
          className={`bi bi-chevron-down ${
            isOpen ? "transform rotate-180" : ""
          } transition-all text-[#bbbbbb]`}
        ></i>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight }}
        className="overflow-hidden transition-max-height ease-in-out"
      >
        <p className="mt-4 text-[#bbbbbb]">{description}</p>
      </div>
    </div>
  );
}

export default Details;
