import Card from "./Card";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center py-16 gap-4 scroll-mt-20" id="services">
      <h1 className="text-5xl font-semibold text-white text-center">
        {t(`services`)}
      </h1>
      <p className="text-[#bbbbbb] text-2xl max-md:text-lg w-4/5 text-center">
        {t(`servicesDescription`)}
      </p>
      <div className="flex max-lg:flex-col items-center m-4 justify-center gap-8">
      <Card
        icon="bi bi-palette-fill"
        title={t(`identity`)}
        description={t(`identityDescription`)}
      />
      <Card
        icon="bi bi-discord"
        title={t(`discord`)}
        description={t(`discordDescription`)}
        />
        </div>
    </div>
  );
}

export default Services;
