import Details from "./Details";
import { useTranslation } from "react-i18next";

function Faq() {
    const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center py-16 gap-4 scroll-mt-24" id="faq">
      <h1 className="text-5xl font-semibold text-white text-center">
        {t("faq")}
      </h1>
      <p className="text-[#bbbbbb] text-2xl w-4/5 text-center">
        {t("faqDescription")}
      </p>
      <div className="flex flex-col w-3/4 justify-center mt-8">
        <Details
          title={t("question1")}
          description={t("question1Description")}
        />
        <Details
          title={t("question2")}
          description={t("question2Description")}
        />
        <Details
          title={t("question3")}
          description={t("question3Description")}
        />
        <Details
          title={t("question4")}
          description={t("question4Description")}
        />
      </div>
    </div>
  );
}

export default Faq;
