function Card({ title, description, icon }) {
  return (
    <div className="w-[calc(40%_-_2.5rem)] max-lg:w-3/4 flex flex-col justify-start items-start gap-8 p-8 bg-black-2 rounded-lg shadow-lg">
        <div className="bg-yellow-opacity p-4 rounded-full relative w-24 h-24">
            <i className={`${icon} text-[92px] text-yellow`}></i>
        </div>
        <div>
            <h1 className="text-2xl font-semibold text-white mb-1">{title}</h1>
            <p className="text-[#bbbbbb]">{description}</p>
        </div>
    </div>
  );
}

export default Card;
