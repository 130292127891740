function Button({ href, variant, children,onClick }) {
  const variantClasses = {
    primary: "bg-red pt-2 pb-1 px-4 rounded-full",
    secondary: "bg-transparent pt-2 pb-1",
  };
  return (
    <a
      href={href}
      className={`w-fit text-white text-lg cursor-pointer font-medium ${variantClasses[variant]}`}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export default Button;