import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Numbers from "./components/Numbers";
import Services from "./components/Services";
import Faq from "./components/Faq";
import i18n from "./i18n";
import ScrollToTop from "./components/ScrollToTop";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";

function App() {
  i18n.on("languageChanged", () => {
    document.documentElement.dir = i18n.dir();
    localStorage.setItem("language", i18n.language);
  });

  document.addEventListener("contextmenu", (event) => event.preventDefault());

  return (
    <div className="min-h-screen bg-black relative">
      <Navbar />
      <Hero />
      <Numbers />
      <Services />
      <Faq />
      <ContactUs />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
