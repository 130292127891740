import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

setTimeout(() => {
  const loadingScreen = document.getElementById("loading");
  if (loadingScreen) {
    loadingScreen.style.opacity = 0;
    loadingScreen.style.translate = "translateY(-100%)";
    setTimeout(() => {
      loadingScreen.style.display = "none";
    }, 500);
  }
}, 500);

reportWebVitals();
