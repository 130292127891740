import { useTranslation } from "react-i18next";

function Numbers() {
  const { t } = useTranslation();
  return (
    <div className="bg-black-2 max-md:divide-y-2 divide-dark-gray gap-4 grid grid-cols-3 max-md:grid-cols-1 justify-between items-center relative z-10 p-4 text-white font-semibold">
      <div className="flex flex-col gap-4 text-7xl text-red items-center text-center py-12">
        +500
        <p className="text-xl text-white">{t("projectsCompleted")}</p>
      </div>
      <div className="flex flex-col gap-4 text-7xl text-red items-center text-center py-12">
        +700
        <p className="text-xl text-white">{t("satifiedCustomers")}</p>
      </div>
      <div className="flex flex-col gap-4 text-7xl text-red items-center text-center py-12">
        +100
        <p className="text-xl text-white">{t("loyalClients")}</p>
      </div>
    </div>
  );
}

export default Numbers;
