import Details from "./Details";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./ScrollToTop";

function Footer() {
  const { t } = useTranslation();
  return (
    <div
      className="flex flex-col items-center py-8 gap-6 bg-green relative"
      id="footer"
    >
      <img src="/logo-white.png" className="h-8" alt="logo" draggable="false" />
      <div className="flex gap-4 text-white text-lg lg:text-xl md:text-lg sm:text-base text-base max-md:flex-col max-md:text-center">
        <a href="#home" className="hover:border-white border-b-2 border-transparent transition-all">{t("home")}</a>
        <a href="#services" className="hover:border-white border-b-2 border-transparent transition-all">{t("services")}</a>
        <a href="#faq" className="hover:border-white border-b-2 border-transparent transition-all">{i18n.language === "en" ? "FAQ" : t("faq")}</a>
        <a href="#contact" className="hover:border-white border-b-2 border-transparent transition-all">{t("contact")}</a>
      </div>
      <div className="flex items-center gap-4">
        <a
          href="https://instagram.com/themurvi"
          className="w-14 h-14 rounded-full text-white border-2 border-white p-2 flex justify-center items-center hover:bg-white hover:text-green transition-all"
        >
          <i className="bi bi-instagram text-3xl m-auto flex items-center justify-center"></i>
        </a>
        <a
          href="https://discord.gg/Zk3EX8TZgT"
          className="w-14 h-14 rounded-full text-white border-2 border-white p-2 flex justify-center items-center hover:bg-white hover:text-green transition-all"
        >
          <i className="bi bi-discord text-3xl m-auto flex items-center justify-center"></i>
        </a>
        <a
          href="https://behance.net/d2nji"
          className="w-14 h-14 rounded-full text-white border-2 border-white p-2 flex justify-center items-center hover:bg-white hover:text-green transition-all"
        >
          <i className="bi bi-behance text-3xl m-auto flex items-center justify-center"></i>
        </a>
      </div>
      <p className="text-white text-lg mt-auto">
        &copy; {new Date().getFullYear()} {t("murviCo")} {t("rightsReserved")}
        </p>
    </div>
  );
}

export default Footer;
