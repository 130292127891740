import i18n from "../i18n";
import Button from "./Button";
import { useTranslation } from "react-i18next";

function Hero() {
  const { t } = useTranslation();
  return (
    <div id="home" className="bg-pattern overflow-hidden min-h-[95vh] max-md:min-h-[90vh] overflow-x-hidden flex max-md:flex-col-reverse justify-center md:justify-between gap-16 items-center px-12 max-md:px-4">
      <div className="flex flex-col gap-4 lg:text-5xl md:text-4xl text-4xl relative z-50">
        <h1 className="font-medium text-white leading-tight max-md:text-3xl">
          {t("welcome")} {" "}
          <span className="text-red font-bold">{t("murvi")}</span>
          <br />
          {t("heroSubtitle")}
        </h1>
        <p className="text-[#bbbbbb] lg:text-2xl md:text-xl sm:text-lg text-base w-4/5 max-md:w-full">
          {t("heroDescription")}
        </p>
        <div className="flex items-center gap-4">
          <Button variant={"primary"} onClick={() => window.scroll({ top: 500, behavior: "smooth" })}>
            {t("getStarted")}
          </Button>
          <Button href="/#contact" variant={"secondary"}>
            {t("contactUs")}
          </Button>
        </div>
      </div>
      <div>
        <img src="/icon.png" alt="icon" className="w-[20vw] relative z-50" draggable="false" />
      </div>
    </div>
  );
}

export default Hero;
