import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";

function ContactUs() {
  const { t } = useTranslation();
  const [dropdown, setDropdown] = useState(false);
  const maxHeightRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");
  const [modal, setModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    instagram: "",
    type: "",
    message: "",
  });

  useEffect(() => {
    setMaxHeight(dropdown ? `${maxHeightRef.current.scrollHeight}px` : "0px");
  }, [dropdown]);

  useEffect(() => {
    document.body.style.overflowY = modal ? "hidden" : "auto";
  }, [modal]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));

    if (id === "email" && value !== "" && !formData.instagram) {
      const emailPattern = e.target.getAttribute("data-pattern");
      const isValid = new RegExp(emailPattern).test(value);
      handleValidation(id, isValid, "invalidEmail");
    }
  };

  const handleValidation = (id, isValid, errorMessageKey) => {
    const errorElement = document.getElementById(`error-${id}`);
    const inputElement = document.getElementById(id);

    if (!isValid) {
      errorElement.innerText = t(errorMessageKey);
      errorElement.classList.remove("hidden");
      errorElement.classList.add("flex");
      inputElement.classList.remove("border-zinc-800");
      inputElement.classList.add("border-red");
    } else {
      errorElement.classList.add("hidden");
      errorElement.classList.remove("flex");
      inputElement.classList.add("border-zinc-800");
      inputElement.classList.remove("border-red");
      errorElement.innerHTML = "";
    }

    setTimeout(() => {
      errorElement.classList.add("hidden");
      errorElement.classList.remove("flex");
      inputElement.classList.add("border-zinc-800");
      inputElement.classList.remove("border-red");
      errorElement.innerHTML = "";
    }, 2500);
  };

  const validateFields = () => {
    const missingFields = Object.keys(formData).filter((key) => {
      if (key !== "email" && key !== "instagram" && !formData[key]) return true;
      if (
        (key === "email" || key === "instagram") &&
        !formData.email &&
        !formData.instagram
      )
        return true;
      return false;
    });

    if (missingFields.length > 0) {
      missingFields.forEach((key) => {
        const inputElement = document.getElementById(key);
        console.log(inputElement, key);
        const errorElement = document.getElementById(`error-${key}`);
        inputElement.classList.remove("border-zinc-800");
        inputElement.classList.add("border-red");
        errorElement.innerText = t("thisField");
        errorElement.classList.remove("hidden");
        errorElement.classList.add("flex");

        setTimeout(() => {
          inputElement.classList.add("border-zinc-800");
          inputElement.classList.remove("border-red");
          errorElement.classList.add("hidden");
          errorElement.classList.remove("flex");
          errorElement.innerHTML = "";
        }, 2500);
      });
      return false;
    }

    return true;
  };

  const send = async () => {
    if (!validateFields()) return;

    setSending(true);

    try {
      const response = await fetch("https://api.murvi.co/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 429) {
        setSuccess("ratelimited");
        setModal(true);
        return;
      }

      const data = await response.json();
      setSuccess(data.success);
      setModal(true);

      setFormData({
        name: "",
        email: "",
        instagram: "",
        type: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending form data:", error);
      setSuccess(false);
    } finally {
      setSending(false);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center mx-auto py-16 gap-4 relative z-10 w-9/12 scroll-mt-20"
      id="contact"
    >
      <h1 className="text-5xl font-semibold text-white text-center">
        {t("contactUs")}
      </h1>
      <div className="hidden max-md:flex flex-col items-start justify-start w-full px-4 text-start gap-2">
        <div className="flex items-start gap-2 w-full">
          <i className="bi bi-info-circle text-xl text-red"></i>
          <p className="text-[#bbbbbb] text-sm text-start">
            {t("emailOrInstagram")}
          </p>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-red text-2xl px-1">*</span>
          <p className="text-[#bbbbbb] text-sm text-start">
            {t("thisField")}
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full max-md:w-full text-sm max-md:px-4 mx-4 justify-center gap-x-4 gap-y-4">
        <div className="flex max-md:flex-col items-center justify-center gap-2 mt-3">
          <div className="flex flex-col w-full relative">
            <h1 className="text-gray pb-1">
              {t("name")}: <span className="text-red">*</span>
            </h1>
            <input
              id="name"
              type="text"
              placeholder={t("namePlaceholder")}
              value={formData.name}
              onChange={handleInputChange}
              className="p-4 w-full transition-all focus:outline-none focus:border-gray bg-black-2 placeholder:text-gray border border-zinc-800 rounded-lg text-white"
            />
            <div
              id="error-name"
              className="text-red text-sm pt-2 items-center hidden"
            ></div>
          </div>
          <div className="flex flex-col w-full relative">
            <h1 className="text-gray pb-1 flex gap-1">
              {t("email")}:
              <span className="text-red hidden max-md:block">
                <i className="bi bi-info-circle text-red"></i>
              </span>
              <div className="group relative max-md:hidden">
                <span className="text-red">
                  <i className="bi bi-info-circle text-red"></i>
                </span>
                <div className="hidden group-hover:flex items-center px-4 show absolute gap-2 z-[100] -bottom-0.5 start-6 bg-black-2 border border-zinc-800 text-gray p-2 rounded-lg text-sm w-72">
                  <i className="bi bi-info-circle text-red"></i>
                  {t("emailOrInstagram")}
                </div>
              </div>
            </h1>
            <input
              id="email"
              type="email"
              placeholder={t("emailPlaceholder")}
              value={formData.email}
              data-pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
              onChange={handleInputChange}
              className="p-4 w-full transition-all focus:outline-none focus:border-gray bg-black-2 placeholder:text-gray border border-zinc-800 rounded-lg text-white"
            />
            <div
              id="error-email"
              className="text-red text-sm pt-2 items-center hidden"
            ></div>
          </div>
        </div>
        <div className="flex max-md:flex-col items-center justify-center gap-2 max-md:mt-0 mt-3">
          <div className="flex flex-col w-full relative">
            <h1 className="text-gray pb-1 flex gap-1">
              {t("instagramaccount")}:
              <span className="text-red hidden max-md:block">
                <i className="bi bi-info-circle text-red"></i>
              </span>
              <div className="group relative max-md:hidden">
                <span className="text-red">
                  <i className="bi bi-info-circle text-red"></i>
                </span>
                <div className="hidden group-hover:flex items-center px-4 show absolute gap-2 z-[100] -bottom-0.5 start-6 bg-black-2 border border-zinc-800 text-gray p-2 rounded-lg text-sm w-72">
                  <i className="bi bi-info-circle text-red"></i>
                  {t("emailOrInstagram")}
                </div>
              </div>
            </h1>
            <input
              id="instagram"
              type="text"
              value={formData.instagram}
              placeholder={t("instagramaccountPlaceholder")}
              onChange={handleInputChange}
              className="p-4 w-full transition-all focus:outline-none focus:border-gray bg-black-2 placeholder:text-gray border border-zinc-800 rounded-lg text-white"
            />
            <div
              id="error-instagram"
              className="text-red text-sm pt-2 items-center hidden"
            ></div>
          </div>
          <div className="flex flex-col w-full relative">
            <h1 className="text-gray pb-1">
              {t("messageType")}: <span className="text-red">*</span>
            </h1>
            <div
              id="type"
              onClick={() => setDropdown(!dropdown)}
              className="cursor-pointer relative w-full bg-black-2 placeholder:text-gray border border-zinc-800 rounded-lg text-white p-4"
            >
              <p className={`${formData.type ? "text-white" : "text-gray"}`}>
                {formData.type ? t(formData.type) : t("messageTypePlaceholder")}
              </p>
              <i className="bi bi-chevron-down text-white absolute end-4 top-4"></i>
              <div
                className={`overflow-hidden transition-max-height start-0 absolute bg-black-2 w-full ${
                  dropdown ? "border" : ""
                } border-zinc-800 rounded-lg mt-4`}
                ref={maxHeightRef}
                style={{ maxHeight }}
              >
                {["question", "order", "suggestion", "feedback"].map((type) => (
                  <p
                    key={type}
                    className="p-4 text-white hover:bg-zinc-800"
                    onClick={() => setFormData((prev) => ({ ...prev, type }))}
                  >
                    {t(type)}
                  </p>
                ))}
              </div>
            </div>
            <div
              id="error-type"
              className="text-red text-sm pt-2 items-center hidden"
            ></div>
          </div>
        </div>
        <div className="mt-3 gap-1 flex flex-col">
          <h1 className="text-gray pb-1">
            {t("message")}: <span className="text-red">*</span>
          </h1>
          <textarea
            id="message"
            placeholder={t("messagePlaceholder")}
            value={formData.message}
            onChange={handleInputChange}
            className="p-4 w-full transition-all focus:outline-none focus:border-gray bg-black-2 placeholder:text-gray border-zinc-800 border rounded-lg text-white"
          />
          <div
            id="error-message"
            className="text-red text-sm items-center hidden w-full"
          ></div>
        </div>
        <button
          className={`bg-purple rounded-lg text-white mt-4 p-4 ${
            sending ? "cursor-not-allowed" : ""
          }`}
          onClick={() => (sending ? null : send())}
        >
          {sending ? <LoadingSpinner /> : t("send")}
        </button>
      </div>
      <div
        id="modal"
        className={`fixed top-0 left-0 w-full h-full overflow-hidden backdrop-blur-md ${
          modal ? "flex" : "hidden"
        } justify-center items-center`}
      >
        <div className="bg-black-2 opacity-50 w-full h-full absolute z-50 transition-all"></div>
        <div className="bg-black p-8 rounded-lg border border-[#242424] text-center z-[999] flex flex-col show-modal">
          <div className="size-20 bg-red rounded-full flex items-center justify-center m-auto my-3 text-white">
            {success == true ? (
              <i className="bi bi-check2 text-5xl flex"></i>
            ) : success == "ratelimited" ? (
              <i className="bi bi-exclamation-triangle text-5xl flex"></i>
            ) : (
              <i className="bi bi-x text-5xl flex"></i>
            )}
          </div>
          <h1 className="text-2xl font-semibold text-white">
            {success == true ? t("success") : t("oops")}
          </h1>
          <p className="text-lg text-[#ccc]">
            {success == true
              ? t("successMessage")
              : success == "ratelimited"
              ? t("ratelimit")
              : t("error")}
          </p>
          {success == true && (
            <span className="text-xs text-gray mt-3">
              {t("successNote").split(" <br> ")[0]}
              <br />
              {t("successNote").split(" <br> ")[1]}
            </span>
          )}
          <button
            className="bg-red p-4 rounded-lg text-white mt-4"
            onClick={() => setModal(false)}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
